import { PermissionContext } from '@ardoq/access-control';
import { APIPresentationAssetAttributes, ArdoqId } from '@ardoq/api-types';
import { presentationAccessControlOperations } from 'resourcePermissions/accessControlHelpers/presentations';
import { PresentationsStreamShape } from './types';

const hasSlides = (presentation: APIPresentationAssetAttributes): boolean => {
  const slides = presentation.slides;
  return Boolean(slides && slides.length);
};

const isEditableOrHasSlides = (
  presentation: APIPresentationAssetAttributes,
  permissionContext: PermissionContext
): boolean => {
  return (
    presentationAccessControlOperations.canEditPresentation(
      permissionContext,
      presentation
    ) || hasSlides(presentation)
  );
};

const getPresentationById = (
  presentationsState: PresentationsStreamShape,
  id: ArdoqId
): APIPresentationAssetAttributes | undefined => presentationsState.byId[id];

const getPresentationsList = (
  presentationsState: PresentationsStreamShape
): APIPresentationAssetAttributes[] => presentationsState.list;

const getPresentationThatContainsSlide = (
  presentationsState: PresentationsStreamShape,
  slideId: ArdoqId
) =>
  presentationsState.list.find(presentation =>
    presentation.slides.includes(slideId)
  );

export const presentationOperations = {
  hasSlides,
  isEditableOrHasSlides,
  getPresentationById,
  getPresentationsList,
  getPresentationThatContainsSlide,
};
