import { ArdoqId } from '@ardoq/api-types';
import { documentArchiveAttachments } from 'collections/documentArchive';
import {
  AttachmentWithContext,
  DocumentArchiveContext,
} from 'components/DocumentBrowser/types';
import documentArchiveAttachmentOperations from 'streams/documentArchive/documentArchiveAttachmentOperations';

const getIsImage = (attachment: AttachmentWithContext) =>
  /^image/.test(attachment['content-type'] || '');

const getDocumentArchiveAttachments = (): AttachmentWithContext[] =>
  documentArchiveAttachments.toJSON();

const getDocumentArchiveImages = ({ wsId }: { wsId: ArdoqId }) =>
  getDocumentArchiveAttachments().filter(
    attachment =>
      getIsImage(attachment) &&
      documentArchiveAttachmentOperations.isOrgOrWorkspaceAttachment(
        attachment,
        wsId
      )
  );

const getWorkspacesAndOrgAttachments = (workspaceIds: Set<ArdoqId>) =>
  getDocumentArchiveAttachments().filter(
    attachment =>
      documentArchiveAttachmentOperations.isOrgAttachment(attachment) ||
      workspaceIds.has(attachment.rootWorkspace)
  );

const getWorkspaceAndOrgAttachments = (wsId: ArdoqId) =>
  getDocumentArchiveAttachments().filter(attachment =>
    documentArchiveAttachmentOperations.isOrgOrWorkspaceAttachment(
      attachment,
      wsId
    )
  );

const getAttachmentById = (id: string): AttachmentWithContext | null =>
  getDocumentArchiveAttachments().find(attachment => attachment._id === id) ??
  null;

const getAttachmentByName = (filename: string): AttachmentWithContext | null =>
  getDocumentArchiveAttachments().find(
    attachment => attachment.filename === filename
  ) ?? null;

const getAttachmentsNames = () =>
  getDocumentArchiveAttachments().map(({ filename }) => filename);

const getGlobalAttachments = () => {
  const attachments = getDocumentArchiveAttachments();
  return attachments.filter(
    attachment => attachment.context === DocumentArchiveContext.ORG
  );
};

export const documentArchiveInterface = {
  getDocumentArchiveAttachments,
  getDocumentArchiveImages,
  getAttachmentById,
  getAttachmentByName,
  getWorkspacesAndOrgAttachments,
  getWorkspaceAndOrgAttachments,
  getAttachmentsNames,
  getGlobalAttachments,
};
