import {
  PermissionContext,
  permissionsOperations,
} from '@ardoq/access-control';
import {
  APIPresentationAssetAttributes,
  PresentationReadPermissions,
  PresentationWritePermissions,
} from '@ardoq/api-types';

const isPresentationOwner = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes
): boolean => {
  return presentation.createdBy === permissionContext.user?._id;
};

const areWritePermissionsLimitedToCurrentUser = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes
): boolean => {
  return (
    presentation?.writeAccess === PresentationWritePermissions.ME &&
    isPresentationOwner(permissionContext, presentation)
  );
};

const canCurrentUserOpenInApp = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes
): boolean => {
  const authorizedSlides = presentation.authorizedSlides ?? [];
  const limitedToUser = areWritePermissionsLimitedToCurrentUser(
    permissionContext,
    presentation
  );
  return (
    presentation.writeAccess === PresentationWritePermissions.WS ||
    limitedToUser ||
    (authorizedSlides && authorizedSlides.length > 0)
  );
};

/**
 * Check if the current user has the correct access to
 * edit a presentation.
 * @returns {boolean}
 */
const canEditPresentation = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes
): boolean => {
  if (
    !presentation ||
    !permissionsOperations.isLoadedPermissionContext(permissionContext) ||
    !permissionsOperations.isOrgWriter(permissionContext.user)
  ) {
    return false;
  }

  if (presentation.writeAccess === PresentationWritePermissions.ME) {
    return (
      isPresentationOwner(permissionContext, presentation) ||
      permissionsOperations.isOrgAdmin(permissionContext.user)
    );
  }
  return true;
};

const isPublic = (presentation?: APIPresentationAssetAttributes): boolean =>
  presentation?.readAccess === PresentationReadPermissions.ALL;

/**
 * A user can edit access rights if user is an admin, OR:
 * A user can edit access rights if it's their presentation and is not public
 */
const canCurrentUserEditAccessRights = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes,
  canCurrentUserWrite: boolean,
  currentUserIsOrgAdmin: boolean
) => {
  if (currentUserIsOrgAdmin) return true;
  const canEdit =
    canCurrentUserWrite &&
    !isPublic(presentation) &&
    isPresentationOwner(permissionContext, presentation);
  return canEdit;
};

/**
 * Check if the current user has the correct access to
 * copy a presentation.
 * @returns {boolean}
 */
const canCopyPresentation = (
  permissionContext: PermissionContext,
  presentation: APIPresentationAssetAttributes
): boolean => {
  const userIsOrgWriter = Boolean(
    permissionContext.user &&
      permissionsOperations.isOrgWriter(permissionContext.user)
  );

  return (
    presentationAccessControlOperations.canCurrentUserOpenInApp(
      permissionContext,
      presentation
    ) && userIsOrgWriter
  );
};

export const presentationAccessControlOperations = {
  /**
   * Check if the current user has the correct access to
   * edit a presentation.
   * @returns {boolean}
   */
  canEditPresentation,
  isPresentationOwner,
  canCurrentUserOpenInApp,
  isPublic,
  canCurrentUserEditAccessRights,
  canCopyPresentation,
};
