import { without } from 'lodash';
import {
  APIComponentAttributes,
  ArdoqId,
  APIPresentationAssetAttributes,
} from '@ardoq/api-types';
import { ContextSort, ExcludeFalsy } from '@ardoq/common-helpers';
import { ContextShape } from '@ardoq/data-model';

// Note: `collections/*' cannot be imported into this file without creating a
// circular reference error. Hence using the AQ object to access them.
// Also note that you should not write these to a variable like
// ```
// const References = AQ.references
// ```
// because the values are assigned too early causing it to be set to undefined,
// in particular for gridEditor2023.
import AQ from 'ardoq';
import Context, {
  CloseWorkspaceOpts,
  LoadWorkspacesOpts,
  StateOpts,
} from 'context';
import { logError } from '@ardoq/logging';
import { CurrentContext } from './types';

// NOTE:
// The purpose of BB interfaces is to avoid exposing the BB model. In practice
// this means you should __never__ return a BB model from these functions.

const getCurrentWsId = (): ArdoqId | null => {
  return Context.activeWorkspaceId();
};

const getCurrentComponentId = (): ArdoqId | null => {
  return Context.componentId();
};

const isSelectedComponent = (id: ArdoqId): boolean => {
  return getCurrentComponentId() === id;
};

const getLoadedWorkspaceIds = (): ArdoqId[] =>
  Context.workspaces().map(({ id }) => id);

const setComponentById = (componentId: string): Promise<void> => {
  const component = AQ.globalComponents.get(componentId);
  return Context.setComponent(component);
};

const setScenarioById = (scenarioId: string): void => {
  Context.setScenarioId(scenarioId);
};

const unsetComponent = (): Promise<void> => {
  return Context.setComponent();
};

const setPresentation = (
  presentation: APIPresentationAssetAttributes
): void => {
  return Context.setPresentation(presentation);
};

const setReferenceById = (
  referenceId: string,
  skipEvent?: boolean | undefined
): void => {
  const reference = AQ.references.get(referenceId) ?? null;
  return Context.setReference(reference, skipEvent);
};

/**
 * Closing a workspace means removing it from the workspaces in Context and
 * clearing components, references, tags, and linked/connected workspaces that
 * are no longer in use, as well as closing web-socket listeners on that
 * workspace.
 */
const closeWorkspace = async (
  workspaceId: ArdoqId,
  options: CloseWorkspaceOpts
) => {
  const workspace = AQ.context
    .workspaces()
    .find(workspace => workspace.getId() === workspaceId);
  if (!workspace) {
    return { error: 'Workspace does not exist in context' };
  }
  await Context.closeWorkspace(workspace, options);
  return { error: null };
};

/**
 * See closeWorkspace() for docs.
 */
const closeWorkspaces = async (
  workspaceIds: ArdoqId[],
  options: CloseWorkspaceOpts
) => {
  return Promise.allSettled(
    workspaceIds.map(async workspaceId => {
      return contextInterface.closeWorkspace(workspaceId, options);
    })
  );
};

const closeAllWorkspaces = async () => {
  await Context.closeAllWorkspaces();
};

const clearContextWorkspace = (): Promise<void> => {
  return Context.clearContextWorkspace();
};

type LoadWorkspacesOptsWithoutWorkspaces = Omit<
  LoadWorkspacesOpts,
  'workspaces' | 'contextWorkspace'
>;
type CtxLoadWorkspacesOpts = LoadWorkspacesOptsWithoutWorkspaces & {
  /**
   * Ids of workspaces that should be loaded into context in addition to
   * existing set of workspaces.
   */
  workspaceIds: string[];
  /**
   * Set the selected workspace
   */
  contextWorkspaceId?: string;
};

const loadWorkspaces = (opts: CtxLoadWorkspacesOpts): Promise<void> => {
  const { contextWorkspaceId, workspaceIds } = opts;
  const contextWorkspace = contextWorkspaceId
    ? AQ.workspaces.get(contextWorkspaceId)
    : undefined;

  const workspaces = workspaceIds
    .map(workspaceId => AQ.workspaces.get(workspaceId))
    .filter(ExcludeFalsy);

  return Context.loadWorkspaces({
    ...opts,
    contextWorkspace,
    workspaces,
  });
};

/**
 * Cleanup deleted workspaces that was loaded in context.
 * Not suitable for use when "closing" a workspace because references can still
 * point across workspaces.
 */
const cleanupDeletedWorkspacesInContext = (_workspaceIds: ArdoqId[]) => {
  // Get ids of workspaces that are loaded into context
  const workspaceIdsInContext = new Set(getLoadedWorkspaceIds());
  const workspaceIdsToClose = new Set(
    _workspaceIds.filter(workspaceId => workspaceIdsInContext.has(workspaceId))
  );

  // cleanup references
  const referencesToRemove = AQ.references.filter(ref =>
    workspaceIdsToClose.has(ref.get('rootWorkspace'))
  );
  AQ.references.batchRemoveReferences(referencesToRemove);

  // cleanup components
  const componentsToRemove = AQ.globalComponents.filter(comp =>
    workspaceIdsToClose.has(comp.get('rootWorkspace'))
  );
  AQ.globalComponents.batchRemoveComponents(componentsToRemove);

  // cleanup tags
  // handled in separate routine

  // cleanup workspace
  AQ.workspaces.remove(workspaceIdsToClose); // remove workspace if it's in context

  return closeWorkspaces(Array.from(workspaceIdsToClose), {
    isRegisterLoadedState: true,
  });
};

const setSort = (sort: ContextSort) => {
  Context.setSort(sort.attr, sort.name, sort.order);
};

const isContextWorkspace = (workspaceId: ArdoqId) => {
  return Context.activeWorkspaceId() === workspaceId;
};

const getSelectedComponent = (): APIComponentAttributes | undefined => {
  const model = Context.component();
  if (!model) return;
  return structuredClone(model.attributes);
};

/**
 * Context.setX functions are mutually exclusive, so to set all attributes of
 * context we need to diff the incoming object with current context in order
 * to determine which functions to call.
 * It is assumed that the incoming context is consistent with the rules in
 * Context. For example: componentId AND referenceId cannot be set at the same
 * time.
 */
const setContext = async (incoming: ContextShape) => {
  const {
    componentId = '',
    referenceId = '',
    scenarioId = '',
    workspaceId = '',
    workspacesIds = [],
  } = incoming;
  const current = {
    componentId: Context.componentId() ?? '',
    referenceId: Context.referenceId() ?? '',
    scenarioId: Context.scenarioId() ?? '',
    workspaceId: Context.activeWorkspaceId() ?? '',
    workspacesIds: getLoadedWorkspaceIds(),
  };

  // Close workspaces that are no longer in context
  const closedWorkspaceIds = without(current.workspacesIds, ...workspacesIds);
  if (closedWorkspaceIds.length > 0) {
    await closeWorkspaces(closedWorkspaceIds, { isRegisterLoadedState: false });
  }

  // Open workspaces that are new, and set contextWorkspace
  const openedWorkspaceIds = without(workspacesIds, ...current.workspacesIds);

  if (workspaceId !== current.workspaceId) {
    // Omit workspaceId because it will be loaded twice if passed as
    // contextWorkspaceId and within workspaceIds.
    await contextInterface.loadWorkspaces({
      contextWorkspaceId: workspaceId,
      workspaceIds: without(openedWorkspaceIds, workspaceId),
    });
  } else if (openedWorkspaceIds.length > 0) {
    await contextInterface.loadWorkspaces({
      workspaceIds: openedWorkspaceIds,
    });
  }

  if (componentId && scenarioId) {
    logError(
      new Error('context.{componentId,scenarioId} set at the same time')
    );
  }

  // Clear context manually
  Context.setComponent(null);
  Context.setReference(null);
  Context.setScenarioId(null);

  // scenarioId and componentId are mutually exclusive, and since the incoming
  // context is assumed to be consistent with the rules in Context, we can
  // safely set the ids that exist.
  if (componentId) {
    setComponentById(componentId);
  }
  if (referenceId) {
    setReferenceById(referenceId);
  }
  if (scenarioId) {
    setScenarioById(scenarioId);
  }
  setSort(incoming.sort);
};

const getCurrentContext = (): CurrentContext => {
  const workspaceId = getCurrentWsId();

  if (!workspaceId) {
    return undefined;
  }
  return {
    workspaceId,
    componentId: getCurrentComponentId() || undefined,
  };
};

const resetState = (state: StateOpts = {}) => Context.resetState(state);

const unsetReference = () => Context.setReference(null);

export const contextInterface = {
  getCurrentComponentId,
  loadWorkspaces,
  cleanupDeletedWorkspacesInContext,
  closeAllWorkspaces,
  closeWorkspace,
  closeWorkspaces,
  getCurrentWsId,
  getLoadedWorkspaceIds,
  getSelectedComponent,
  isSelectedComponent,
  isContextWorkspace,
  setPresentation,
  setComponentById,
  setReferenceById,
  setScenarioById,
  setSort,
  setContext,
  unsetComponent,
  unsetReference,
  clearContextWorkspace,
  getCurrentContext,
  resetState,
};
