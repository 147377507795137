import Context from 'context';
import Reference from 'models/reference';
import Component from 'models/component';
import Components from 'collections/components';
import Workspaces from 'collections/workspaces';
import Backbone from 'backbone';
import { modelsNeedSaving } from 'collections/helpers/modelsNeedSaving';
import Filters from 'collections/filters';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  notifyReferencesAdded,
  notifyReferencesRemoved,
} from 'streams/references/ReferenceActions';
import { triggerFiltersChangedEvent } from 'streams/filters/FilterActions';
import ViewCollection from './ViewCollection';
import { CollectionView, EVENT_TAG_ADDED, EVENT_TAG_REMOVED } from './consts';
import syncManager, { saveModel } from 'sync/syncManager';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { APIReferenceAttributes, ArdoqId } from '@ardoq/api-types';
import { ComponentBackboneModel, Reference as ReferenceType } from 'aqTypes';
import { getApiUrl } from 'backboneExtensions';
import { notifyWorkspaceClosed } from 'streams/context/ContextActions';
import { subscribeToAction } from 'streams/utils/streamUtils';
import { filter, sortBy } from 'lodash';
import { clearAllEventListenersOnModels } from './helpers/clearAllEventListenersOnModels';

let refsBySource: Record<ArdoqId, ReferenceType[]> = {};
let refsByTarget: Record<ArdoqId, ReferenceType[]> = {};

const getRefsBySource = (id: ArdoqId) => refsBySource[id] || [];
const getRefsByTarget = (id: ArdoqId) => refsByTarget[id] || [];

export class References extends ViewCollection<ReferenceType> {
  missingComp: ComponentBackboneModel;

  constructor(...args: any) {
    super(...args);

    this.model = Reference.model;
    this.url = `${getApiUrl(Backbone.Collection)}/api/reference`;
    this.missingComp = Component.create(
      {
        name: 'Missing component',
        id: -1,
        description: 'This component is missing!',
      },
      { collection: Components.collection }
    );

    this.on('reset', () => {
      this.each(ref => {
        if (!ref.getTargetId()) {
          ref.addTarget(this.missingComp);
        } else if (!ref.getSourceId()) {
          ref.addSource(this.missingComp);
        }
      });
    });

    this.on(
      [EVENT_TAG_ADDED, EVENT_TAG_REMOVED, 'change'].join(' '),
      (comp: ReferenceType) => {
        Filters.updateFiltered(comp);
      }
    );

    this.listenTo(
      this,
      'change',
      (
        { changed }: { changed: Partial<APIReferenceAttributes> } = {
          changed: {},
        }
      ) => {
        const fieldNames = Object.keys(changed);
        Filters.notifyOnAffectingChange({
          fieldNames,
          checkReferences: true,
        });
      }
    );

    subscribeToAction(notifyWorkspaceClosed, ({ workspaceId }) => {
      const openWorkspaces = Context.workspaces();

      this.saveAllChangedModels(true);

      if (openWorkspaces.length === 0) {
        clearAllEventListenersOnModels(this.models);
        this.reset();
      } else {
        const openWorkspaceIds = new Set(
          openWorkspaces.map(openWs => openWs.id)
        );
        const referencesToRemove = this.filter(ref => {
          const rootWsId = ref.get('rootWorkspace');
          const targetWsId = ref.get('targetWorkspace');

          const internalRef =
            rootWsId === workspaceId && targetWsId === workspaceId;
          const rootInClosingTargetNotOpen =
            rootWsId === workspaceId && !openWorkspaceIds.has(targetWsId);
          const targetInClosingRootNotOpen =
            targetWsId === workspaceId && !openWorkspaceIds.has(rootWsId);

          if (
            internalRef ||
            rootInClosingTargetNotOpen ||
            targetInClosingRootNotOpen
          ) {
            ref.off();
            return true;
          }
          return false;
        });
        this.batchRemoveReferences(referencesToRemove);
      }
      this.buildCacheForSourceAndTargetMaps();
    });

    this.on(
      'add remove reset change:target change:source',
      () => {
        dispatchAction(triggerFiltersChangedEvent());
        this.buildCacheForSourceAndTargetMaps();
      },
      this
    );
  }

  buildCacheForSourceAndTargetMaps() {
    refsBySource = this.groupBy(function (ref) {
      return ref.get('source');
    });

    refsByTarget = this.groupBy(function (ref) {
      return ref.get('target');
    });
  }

  getSourceRefsByComponent(
    comp: ComponentBackboneModel,
    skipFilters?: boolean
  ) {
    return sortBy(
      filter(
        getRefsBySource(comp.id),
        ref =>
          ref &&
          !this.excludedIds.has(ref.id) &&
          ref.getSource() &&
          (skipFilters || ref.isIncludedInContextByFilter())
      ),
      function (ref) {
        return ref.get('order');
      }
    );
  }

  getTargetRefsByComponent(
    comp: ComponentBackboneModel,
    skipFilters?: boolean
  ) {
    return sortBy(
      filter(
        getRefsByTarget(comp.id),
        ref =>
          ref &&
          !this.excludedIds.has(ref.id) &&
          ref.getTarget() &&
          (skipFilters || ref.isIncludedInContextByFilter())
      ),
      function (ref) {
        return ref.get('order');
      }
    );
  }

  saveAllChangedModels(forced: boolean) {
    const references = modelsNeedSaving({
      forced,
      models: this.models,
      hasWriteAccessCheck: ref => {
        const ws = Workspaces.collection.get(ref.get('rootWorkspace'));
        return ws ? ws.hasWriteAccess() : false;
      },
    });

    if (isScenarioMode()) {
      for (const reference of references) {
        saveModel(reference);
      }
    } else {
      syncManager.batchSave(references, 'references');
    }
  }

  batchRemoveReferences(referencesToRemove: ReferenceType[]) {
    this.remove(referencesToRemove, { silent: true });
    dispatchAction(
      notifyReferencesRemoved({
        referenceIds: referencesToRemove.map(ref => ref.id),
      })
    );
  }

  batchLoadReferences(references: Record<string, APIReferenceAttributes>) {
    const connectedWorkspaces = Context.getConnectedWorkspaceIds();

    const referencesToAdd = Object.values(references).filter(
      referenceAttributes =>
        connectedWorkspaces.has(referenceAttributes.rootWorkspace) &&
        !this.get(referenceAttributes._id)
    );

    referencesToAdd.forEach(referenceAttributes => {
      this.add(referenceAttributes, {
        silent: true,
      });
    });
    this.buildCacheForSourceAndTargetMaps();

    dispatchAction(
      notifyReferencesAdded({
        referenceIds: referencesToAdd.map(r => r.id),
      })
    );
  }
}

const references = new References();

references.createCollectionView(CollectionView.BASE_VIEW);

export default {
  collection: references,
  createView: (name: string, includedSets = []) =>
    references.createCollectionView(name, includedSets),
};
