import Context from 'context';
import Tags from 'collections/tags';
import BaseBackboneModel from 'BaseBackboneModel';
import Fields from 'collections/fields';
import { GroupType } from '@ardoq/api-types';
import { logWarn } from '@ardoq/logging';
import logMissingModel from './logMissingModel';
import { workspaceInterface } from '@ardoq/workspace-interface';

const {
  PARENT_ALL,
  COMPONENT,
  WORKSPACE,
  REFERENCE,
  REFERENCE_CONTEXTUAL,
  PARENT,
  CHILD,
  FIELD,
  TAG,
  SUBDIVISION,
} = GroupType;

// sorted according to the dropdown design in GroupByEditorComponent.tsx

const shorthandTypes: Partial<Record<GroupType, string>> = {
  [REFERENCE]: 'r',
  [REFERENCE_CONTEXTUAL]: 'rc',
  [COMPONENT]: 'ct',
  [PARENT]: 'p',
  [PARENT_ALL]: 'pa',
  [CHILD]: 'c',
  [FIELD]: 'f',
  [TAG]: 't',
  [SUBDIVISION]: 's',
  [WORKSPACE]: 'ws',
};

const directions = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};

const REQUIRED_ATTRS: Partial<Record<GroupType, ('targetId' | 'direction')[]>> =
  {
    [REFERENCE]: ['targetId', 'direction'],
    [REFERENCE_CONTEXTUAL]: ['targetId', 'direction'],
    [PARENT]: ['targetId'],
    [CHILD]: ['targetId'],
    [FIELD]: ['targetId'],
    [TAG]: ['targetId'],
  };

const GROUPBY_MAP_PREFIX = 'g_m';
function getDefaultTargetIdAttribute(
  type?: GroupType
): string | number | undefined {
  const contextWorkspaceId = Context.activeWorkspaceId();
  if (!contextWorkspaceId) {
    return undefined;
  }
  const referenceTypes =
    workspaceInterface.getReferenceTypes(contextWorkspaceId) ?? {};
  const compTypes = workspaceInterface.getComponentTypes(contextWorkspaceId);

  if (!referenceTypes || !compTypes) {
    logMissingModel({
      id: contextWorkspaceId,
      modelTypeName: 'workspace',
      rootWorkspace: contextWorkspaceId,
    });
  }
  switch (type) {
    case GroupType.REFERENCE:
    case GroupType.REFERENCE_CONTEXTUAL:
      return referenceTypes[Object.keys(referenceTypes)[0]].id;
    case GroupType.PARENT:
      return compTypes[0]?.id;
    case GroupType.CHILD:
      return compTypes[0]?.id;
    case GroupType.FIELD: {
      const contextModelId = Context.activeModelId();
      if (!contextModelId) {
        return undefined;
      }
      const field = Fields.collection.find(
        ({ attributes: { model, plugin } }) =>
          model === contextModelId || plugin
      );
      return field?.id || undefined;
    }
    case GroupType.TAG: {
      const tag = Tags.collection.find(tag => {
        return tag.get('rootWorkspace') === contextWorkspaceId;
      });
      return (tag && tag.id) || undefined;
    }
  }
}

interface GroupByArgs {
  targetId?: string;
  workspaceId?: string;
  type?: string;
  direction?: string;
  sublabel?: string;
}
class GroupBy extends BaseBackboneModel({}) {
  constructor(attributes: GroupByArgs = {}) {
    super(attributes);
    let targetId =
      attributes.targetId ||
      getDefaultTargetIdAttribute(attributes.type as GroupType);
    if (targetId !== undefined && targetId !== null) {
      targetId = String(targetId);
    }
    const workspaceId = attributes.workspaceId || Context.activeWorkspaceId();
    if (!workspaceId) {
      logWarn(
        Error('GroupBy initialized with no workspace'),
        null,
        attributes as Record<string, string>
      );
    }
    this.set({
      workspaceId,
      targetId,
      type: attributes.type,
      direction:
        attributes.direction ||
        (attributes.type === GroupType.REFERENCE ||
        attributes.type === GroupType.REFERENCE_CONTEXTUAL
          ? directions.incoming
          : undefined),
      sublabel: attributes.sublabel || '',
    });
  }

  isValid() {
    if (this.get('type')) {
      const requiredAttrs: ('targetId' | 'direction' | 'workspaceId')[] =
        REQUIRED_ATTRS[this.get('type') as GroupType] || [];
      if (this.isTiedToWorkspace()) {
        requiredAttrs.push('workspaceId');
      }
      return requiredAttrs.every(
        attr => !!(this.get(attr) || this.get(attr) === 0)
      );
    }
    return false;
  }

  isTiedToWorkspace() {
    switch (this.get('type')) {
      case REFERENCE:
      case REFERENCE_CONTEXTUAL:
      case PARENT:
      case CHILD:
      case FIELD:
      case TAG:
        return true;
    }
    return false;
  }
  isTiedToField() {
    return this.get('type') === FIELD;
  }

  getType() {
    return this.attributes.type;
  }

  getTargetID() {
    return this.attributes.targetId;
  }

  getDirection() {
    return this.attributes.direction;
  }

  getSublabel() {
    return this.attributes.sublabel;
  }

  getWorkspaceID() {
    return this.attributes.workspaceId;
  }

  encodeURI() {
    const compactParams: {
      g?: string;
      t?: string;
      wid?: string;
      d?: string;
      sl?: string;
    } = {
      g: getShorthandType(this.getType()),
    };
    if (this.isTiedToWorkspace()) {
      compactParams.t = this.getTargetID();
      compactParams.wid = this.getWorkspaceID();
    }
    if (
      this.getType() === GroupType.REFERENCE ||
      this.getType() === GroupType.REFERENCE_CONTEXTUAL
    ) {
      compactParams.d = getShorthandDirection(this.getDirection());
    }
    if (this.getSublabel()) {
      compactParams.sl = this.getSublabel();
    }
    return `${GROUPBY_MAP_PREFIX}${window.encodeURI(
      JSON.stringify(compactParams)
    )}&`;
  }
}
function getShorthandType(fullType: GroupType) {
  return shorthandTypes[fullType];
}
function getShorthandDirection(direction?: string) {
  return direction && direction.charAt(0);
}
function getFullType(shorthandType: string) {
  return Object.keys(shorthandTypes).find(
    key => shorthandTypes[key as GroupType] === shorthandType
  );
}
/** Converts a URI-Encoded group by to a GroupByAttributes-like object. Used by the router when loading a workspace with a perspective in the queryString. */
function parseUriEncodedGroupBy(string: string) {
  if (!string.startsWith(GROUPBY_MAP_PREFIX)) {
    return legacyParseUriEncodedGroupBy(string);
  }

  try {
    const compactJsonStr = string.slice(GROUPBY_MAP_PREFIX.length);
    const compact = JSON.parse(compactJsonStr);
    return {
      type: getFullType(compact.g),
      workspaceId: compact.wid,
      targetId: compact.t,
      direction: compact.d ? (compact.d === 'i' ? 'incoming' : 'outgoing') : '',
      sublabel: compact.sl,
    };
  } catch (err) {
    logWarn(new Error('Error parsing Group By URL'));
  }
}
/**
 * Predecessor to @see parseUriEncodedGroupBy
 * Converts a URI-Encoded group by to a GroupByAttributes-like object, using a URI made prior to August 2018.
 */
function legacyParseUriEncodedGroupBy(string: string) {
  const parser = {
    regexp:
      /g_([a-z]{1,2})(?:_t_(.+?))?_dir_(incoming|outgoing)(?:_wid_([a-z0-9]+))?/i,
    shorthandType: 1,
    targetId: 2,
    direction: 3,
    workspaceId: 4,
  };

  const match = parser.regexp.exec(string);

  return match
    ? {
        workspaceId: match[parser.workspaceId],
        targetId: match[parser.targetId],
        type: getFullType(match[parser.shorthandType]),
        direction: match[parser.direction],
      }
    : {};
}

function createFromString(groupByString: string) {
  return new GroupBy(parseUriEncodedGroupBy(groupByString));
}

export default {
  model: GroupBy,
  types: GroupType,
  directions,
  createFromString,
};
